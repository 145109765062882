
import { computed, DeepReadonly, defineComponent, ref } from "vue";
import { EelarveAndmik } from "@/firebase/parse/types";
import BaseTable from "@/components/table/BaseTable.vue";
import VaadeCharts from "@/components/common/VaadeCharts.vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import DateAsOf from "@/components/common/vaadeheader/DateAsOf.vue";
import VaadeGuard from "@/components/common/VaadeGuard.vue";
import { RouteName } from "@/router/types";

export default defineComponent({
  components: {
    VaadeGuard,
    DateAsOf,
    VaadeCharts,
    BaseTable,
  },
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const parsedData = computed<DeepReadonly<EelarveAndmik> | null>(
      () => municipalityState.investeeringud
    );

    const expandedCode = ref<string | undefined>(undefined);
    const onExpand = (code: string | undefined) =>
      (expandedCode.value = expandedCode.value === code ? undefined : code);

    return {
      RouteName,
      parsedData,
      expandedCode,
      onExpand,
    };
  },
});
